"use strict";

$(document).ready(function() {

	$.CookiesMessage({
		messageText: "We use technical and analytics cookies to ensure that we give you the best experience on our website.",
		messageBg: "#151515",   
		messageColor: "#FFFFFF",
		messageLinkColor: "#F0FFAA",
		closeEnable: false, 
		closeColor: "#444444",
		closeBgColor: "#000000",
		acceptEnable: true,
		acceptText: "Accept & Close",
		infoEnable: false,  
		infoText: "More Info",
		infoUrl: "#",
		cookieExpire: 180 // Cookie expire time (days)
	}); 

	$("#lightSlider").lightSlider({
		item: 3,
		autoWidth: true,
		slideMove: 1, // slidemove will be 1 if loop is true
		slideMargin: 10,

		speed: 400, //ms'
		auto: true,
		loop: true,
		slideEndAnimation: true,
		pause: 2000,

		keyPress: false,
		controls: true,
		prevHtml: '',
		nextHtml: '',
		pager: false,

		rtl: false,
		adaptiveHeight: false,

		enableTouch: true,
		enableDrag: true,
		freeMove: true,
		swipeThreshold: 40,

		responsive: []
	});

	$('#care-types .care-category').matchHeight();
	$('.testimonialWrapper .ddd').matchHeight();

	$('ul.nav li:nth-child(1) a').addClass('active');
});

document.addEventListener('lazyloaded', function(e){
	$.fn.matchHeight._update()
});

$(window).scroll(function() {
	var scroll = $(window).scrollTop();

	if (scroll >= 50) {
		$(".navbar").addClass("white-bottom");

	} else {
		$(".navbar").removeClass("white-bottom");
	}
});